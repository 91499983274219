<div class="relative bg-white h-[50px] border-b shadow-xs">
  <div class="mx-auto px-5">
    <div class="flex items-center justify-between py-2 md:justify-start">
      <!-- Mobile Navigation Menu Button-->
      <div class="sm:order-1 md:hidden">
        <button
          (click)="toggleMobileMenu()"
          type="button"
          class="inline-flex items-center justify-center rounded-md bg-muted p-2 text-muted-foreground focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary hover:bg-muted-foreground hover:text-muted"
          aria-expanded="false">
          <span class="sr-only">Open menu</span>
          <span class="material-symbols-outlined h-6 w-6" style="width: 1.5rem; height: 1.5rem; font-size: 22px">
menu
</span>
          <!-- Heroicon name: outline/menu -->
<!--          <svg-icon src="assets/icons/heroicons/outline/menu.svg" [svgClass]="'h-6 w-6'"> </svg-icon>-->
        </button>
      </div>

      <!-- Logo -->
      <div class="flex items-center justify-start sm:order-2 md:mr-10 lg:hidden">
        <a class="flex items-center justify-center rounded bg-primary p-2 focus:outline-none focus:ring-1">
          <img src="/assets/images/LogoPrimeJaune.png" class="w-[80px]" alt="">

        </a>
      </div>

      <!-- Desktop Menu -->
      <div class="hidden space-x-10 sm:order-3 md:flex">
<!--        <app-navbar-menu></app-navbar-menu>-->
      </div>

      <!-- Profile menu -->
      <div class="items-center justify-end sm:order-4 md:flex md:flex-1 lg:w-0 mt-2">
        <div class="relative ml-3">
          <!-- Profile Button -->
          <button (click)="toggleMenu()" class="flex items-center bg-card text-sm" type="button">
            <span class="material-symbols-outlined mb-0.5">
              {{userAuth().plateform === "BACKOFFICE_COMPANY" ? 'apartment': 'person'}}
            </span>
            <div class="overflow-hidden px-2 text-sm font-medium text-foreground uppercase">
              @if(userAuth().plateform === "BACKOFFICE_COMPANY"){
                {{userAuth().company?.name}}
              } @else {
                {{userAuth().firstname}}  {{userAuth().lastname}}
              }
            </div>
            <span class="material-symbols-outlined">
              @if(isOpen){
                keyboard_arrow_up

              }@else{
                keyboard_arrow_down
              }
            </span>
          </button>
          <!-- Dropdown -->
          <div
            [@openClose]="isOpen ? 'open' : 'closed'"
            class="absolute right-0 z-20 mt-3 w-60 origin-top-right transform border rounded-md bg-[#FFFFFF] py-4 shadow-custom ring-1 ring-transparent ring-opacity-5 transition focus:outline-none">
            <div class="flext-row flex items-center px-4 pb-4">

              <div class="overflow-hidden px-2 text-sm font-normal text-foreground">
                {{userAuth().firstname}}  {{userAuth().lastname}}
                <p class="truncate text-ellipsis text-xs font-normal text-muted-foreground"> {{userAuth().email}}</p>
              </div>
            </div>

            <div class="border-b border-dashed border-border"></div>

            <ul class="my-2 mx-4 flex flex-col">
              @for (item of profileMenu; track $index) {
                <li
                  :key="$index"
                  class="inline-flex items-center gap-2 rounded-md px-3 py-2 text-xs text-[#000] cursor-pointer hover:bg-[#e3a602]/70 font-medium"
                  [ngClass]="{'cursor-not-allowed': !item.active,'cursor-pointer':item.active}" [title]="item?.description"
                  (click)="item.action()">
                  <mat-icon class="material-symbols-outlined menu-icon">{{ item.icon }}</mat-icon>
                  {{ item.title }}
                </li>
              }
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- Mobile menu -->
  <app-navbar-mobile></app-navbar-mobile>
</div>
