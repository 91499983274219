<div
  class="max-h-0 overflow-hidden pt-1 pl-4 transition-all duration-500"
  [ngClass]="{ hidden: !menuService.showSideBar, 'max-h-screen': submenu.expanded }">
  <ul class="flex flex-col border-l border-dashed border-border pl-2 text-muted-foreground">
    <li *ngFor="let sub of submenu.children">
      <div class="group flex rounded text-muted-foreground hover:bg-card hover:text-foreground" (click)="toggleMenu(sub)">
        <!-- Condition -->
        <ng-container
          [ngTemplateOutlet]="sub.children ? childMenu : parentMenu"
          [ngTemplateOutletContext]="{ sub: sub }">
        </ng-container>

        <!-- Parent Menu -->
        <ng-template #parentMenu let-sub="sub">
          <a
            [routerLink]="sub.route"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="w-full px-4 py-2 text-[14px] font-normal text-[#000] flex items-center gap-2">
            <span class="material-symbols-outlined icon" style="font-size: 22px">
{{sub.icon}}
</span>
            {{ sub.label }}
          </a>
        </ng-template>

        <!-- Child Menu -->
        <ng-template #childMenu let-sub="sub">
          <a class="inline-block w-full cursor-pointer px-4 py-2 text-xs font-semibold">
            {{ sub.label }}
          </a>
        </ng-template>
      </div>
    </li>
  </ul>
</div>
