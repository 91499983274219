import { Component } from '@angular/core';
import {CommonModule, NgClass, NgIf, NgTemplateOutlet} from "@angular/common";
import {AngularSvgIconModule} from "angular-svg-icon";
import {MenuService} from "../../services/menu.service";
import {MatIcon} from "@angular/material/icon";
import {SubMenuItem} from "../../models/menu.model";
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import {AuthService} from "../../services/auth/auth.service";
import {SidebarSubmenuComponent} from "./sidebar-submenu/sidebar-submenu.component";


@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [NgClass, NgIf, AngularSvgIconModule, MatIcon, NgTemplateOutlet, RouterLink, CommonModule, RouterLinkActive, SidebarSubmenuComponent],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css'
})
export class SidebarComponent {

  constructor(public menuService: MenuService, protected authService: AuthService) {}

  ngOnInit(): void {}


  public toggleSidebar() {
    this.menuService.toggleSidebar();
  }


  public toggleMenu(subMenu: SubMenuItem) {
    this.menuService.toggleMenu(subMenu);
  }
}
