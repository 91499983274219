<div
  [ngClass]="
    menuService.showMobileMenu
      ? 'pointer-events-auto scale-100 animate-fade-in-up opacity-100 duration-200'
      : 'pointer-events-none scale-95 opacity-0 duration-100 ease-out'
  "
  class="absolute inset-x-0 top-0 z-50 origin-top-right transform p-2 transition md:hidden">
  <div class="rounded-lg bg-[#FFFFFF] shadow-lg">
    <div class="pt-5 pb-6">
      <div class="flex items-center justify-between px-5">
        <div>
          <!-- Logo -->
          <div class="flex items-center justify-start sm:order-2 md:mr-10 lg:hidden">
            <a class="flex items-center justify-center rounded bg-primary p-2 focus:outline-none focus:ring-1">
              <img src="/assets/images/LogoPrimeJaune.png" class="w-[80px]" alt="">
            </a>
          </div>
        </div>
        <div class="-mr-2">
          <button
            (click)="toggleMobileMenu()"
            type="button"
            class="inline-flex items-center justify-center rounded-md p-2 text-muted-foreground transition-transform focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary hover:rotate-90 hover:bg-card hover:text-foreground">
            <span class="sr-only">Close menu</span>
            <span class="material-symbols-outlined">
              close
            </span>
          </button>
        </div>
      </div>
      <div
        class="scrollbar-thumb-rounded scrollbar-track-rounded max-h-[600px] overflow-y-auto px-5 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-muted">
        <div class="pt-16" *ngFor="let menu of menuService.pagesMenu">
          <ul class="flex flex-col gap-[15px] space-y-1">
            @for (item of menu.items; track item.label){
              <li
                *ngIf="!item.routePermission || authService.hasPermission(item.routePermission)"
              >
                <div
                  (click)="toggleMenu(item)"
                  class="group relative w-full text-muted-foreground"
                  [routerLink]="item.route ? item.route : null"
                  [ngClass]="{'disabled-link': !item.route}"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">

                  <!-- Icône -->
                  <div class="pointer-events-none absolute top-[3px]">
                    <span class="material-symbols-outlined menu-icon">{{ item.icon }}</span>
                  </div>

                  <!-- Étiquette -->
                  <div class="flex h-9 cursor-pointer items-center justify-start rounded text-muted-foreground hover:bg-card hover:text-foreground">
                    <a class="ml-12 truncate tracking-wide focus:outline-none text-[16px] font-normal text-[#000]">
                      {{ item.label }}
                    </a>
                  </div>

                  <button
                    *ngIf="item.children"
                    [ngClass]="{ hidden: !menuService.showSideBar, 'rotate-90': item.expanded }"
                    class="pointer-events-none absolute top-1 right-0 flex items-center p-1 text-muted-foreground/50 transition-all duration-500">
                <span class="material-symbols-outlined icon" style="font-size: 22px">
                  chevron_right
                </span>
                  </button>

                  <!-- Tooltip -->
                  <div class="fixed w-full" *ngIf="!menuService.showSideBar">
      <span class="z-1 absolute left-14 -top-[34px] w-auto min-w-max origin-left scale-0 rounded-md bg-foreground p-2 text-xs font-bold text-white bg-black shadow-md transition-all duration-200 group-hover:scale-100">
        {{ item.label }}
      </span>
                  </div>
                </div>
                <app-sidebar-submenu [submenu]="item"></app-sidebar-submenu>
              </li>
            }
          </ul>

          <div class="pt-3" *ngIf="menu.separator">
            <hr class="border-dashed border-border" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
