<div class="flex h-screen w-full overflow-hidden">
  <!-- Sidebar -->
  <app-sidebar></app-sidebar>

  <div class="flex grow flex-col content-start overflow-hidden bg-card">
    <!-- Header -->
    <app-navbar></app-navbar>
    <!-- main content -->
    <div
      id="main-content"
      class="scrollbar-thumb-rounded bg-[#e5e7eb] scrollbar-track-rounded grow overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-muted">
      <div class="mx-auto px-4 py-4 sm:px-8 lg:container">
        <router-outlet></router-outlet>
      </div>
    </div>

    <!-- footer -->

    <!-- Bottom bar -->
  </div>
</div>
