import { Injectable, OnDestroy, signal } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {map, of, Subscription} from 'rxjs';
import {MenuItem, SubMenuItem} from "../models/menu.model";
import {Menu} from "../contants/menu";
import {AuthService} from "./auth/auth.service";
import {catchError} from "rxjs/operators";


@Injectable({
  providedIn: 'root',
})
export class MenuService implements OnDestroy {
  private _showSidebar = signal(true);
  private _showMobileMenu = signal(false);
  private _pagesMenu = signal<MenuItem[] | null>([]);
  private _subscription = new Subscription();
  private userSignal = signal<any>([]);


  constructor(private router: Router, private  authService: AuthService) {

    const jwtToken = localStorage.getItem('jwtToken');
    if (jwtToken) {
      this.authService.getUser().pipe(
        map(response => {
          const user = response?.data;
          if (user?.plateform === 'BACKOFFICE_ADMIN') {
            this._pagesMenu.set(Menu.pagesAdmin);
          } else if (user?.plateform === 'BACKOFFICE_COMPANY') {
            this._pagesMenu.set(Menu.pages);
          }else {
            this._pagesMenu.set(null);
          }
        }),
        catchError(error => {
          console.error('Erreur d\'authentification :', error);
          return of(null);
        })
      ).subscribe();
    } else {
      this._pagesMenu.set(null);
    }

    let sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        /** Expand menu base on active route */
        this._pagesMenu()?.forEach((menu) => {
          let activeGroup = false;
          menu.items.forEach((subMenu) => {
            const active = this.isActive(subMenu.route);
            subMenu.expanded = active;
            subMenu.active = active;
            if (active) activeGroup = true;
            if (subMenu.children) {
              this.expand(subMenu.children);
            }
          });
          menu.active = activeGroup;
        });
      }
    });
    this._subscription.add(sub);
  }

  ngOnInit(): void {
    const jwtToken = localStorage.getItem('jwtToken');

    if (jwtToken) {
      this.authService.getUser().subscribe(
        response => {
          const user = response?.data.user;
          this.userSignal.set(user);
        },
        error => {
          console.error('Erreur d\'authentification :', error);
        }
      );
    }
  }




  get showSideBar() {
    return this._showSidebar();
  }
  get showMobileMenu() {
    return this._showMobileMenu();
  }
  get pagesMenu() {
    return this._pagesMenu();
  }

  set showSideBar(value: boolean) {
    this._showSidebar.set(value);
  }
  set showMobileMenu(value: boolean) {
    this._showMobileMenu.set(value);
  }

  public toggleSidebar() {
    this._showSidebar.set(!this._showSidebar());
  }

  public toggleMenu(menu: any) {
    this.showSideBar = true;
    menu.expanded = !menu.expanded;
  }

  public toggleSubMenu(submenu: SubMenuItem) {
    submenu.expanded = !submenu.expanded;
  }

  private expand(items: Array<any>) {
    items.forEach((item) => {
      item.expanded = this.isActive(item.route);
      if (item.children) this.expand(item.children);
    });
  }

  private isActive(instruction: any): boolean {
    return this.router.isActive(this.router.createUrlTree([instruction]), {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
