import {MenuItem} from "../models/menu.model";

export class Menu {

  public static pagesAdmin: MenuItem[] = [
    {
      group: 'Base',
      separator: false,
      items: [
        {
          icon: 'tune',
          label: 'Tableau de bord',
          route: '/admin',
        },
        {
          icon: 'groups',
          label: 'Utilisateurs',
          route: '/admin/users',
          routePermission: "BO_USER_LIST",
        },
        {
          icon: 'admin_panel_settings',
          label: 'Rôles',
          route: '/admin/roles',
          routePermission: "BO_ROLE_LIST",
        },
        {
          icon: 'domain',
          label: 'Entreprises',
          route: '/admin/companies',
          routePermission: "BO_COMPANY_LIST",
        },
        {
          icon: 'badge',
          label: 'Employés',
          route: '/admin/employees',
          routePermission: "BO_EMPLOYEE_LIST",
        },
        {
          icon: 'live_help',
          label: 'Suggestions',
          routePermission: "BO_SUGGESTION_LIST",
          children: [
            { label: 'Clients', route: '/admin/suggestions/customers', icon: 'group' },
            { label: 'Boutiques', route: '/admin/suggestions/shops', icon: 'shopping_bag' },
            { label: 'Entreprises', route: '/admin/suggestions/companies', icon: 'apartment'},
          ],
        },
      ],
    }
    ]

  public static pages: MenuItem[] = [
    {
      group: 'Base',
      separator: false,
      items: [
        {
          icon: 'dashboard',
          label: 'Tableau de bord',
          route: '/company',
        },
        {
          icon: 'groups',
          label: 'Utilisateurs',
          route: '/company/users',
          routePermission: "COMPANY_USER_LIST",
        },
        {
          icon: 'admin_panel_settings',
          label: 'Rôles',
          route: '/company/roles',
          routePermission: "COMPANY_ROLE_LIST",
        },
        {
          icon: 'badge',
          label: 'Employés',
          route: '/company/employees',
          routePermission: "COMPANY_EMPLOYEE_LIST",
        },
        {
          icon: 'shopping_bag',
          label: 'Achats',
          route: '/company/purchases',
          routePermission:"COMPANY_ACHAT_LIST",
        },
        {
          icon: 'monitoring',
          label: 'Plafond',
          route: '/company/increases',
          routePermission:"COMPANY_PLAFOND_LIST",
        },

      ],
    }
  ]
}
