<div
  [ngClass]="menuService.showSideBar ? 'w-52 xl:w-64' : 'w-[60px]'"
  class="scrollbar-thumb-rounded scrollbar-track-rounded hidden h-full flex-col justify-between overflow-auto shadow-md bg-[#ffffff] pt-3 transition-all duration-300 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-card lg:flex">
  <div class="pl-4">
    <!-- Logo -->
    <div class="relative h-10">
      <div class="flex items-center" *ngIf="menuService.showSideBar">
        <a
          class="flex cursor-pointer items-center justify-center rounded bg-primary p-2 focus:outline-none focus:ring-1">
          <img src="/assets/images/LogoPrimeJaune.png" class="w-[80px]" alt="">
        </a>
      </div>
      <button
        (click)="toggleSidebar()"
        class="absolute top-2 right-0 w-[50px] flex justify-center items-center rounded text-muted-foreground/50 transition-all duration-200 focus:outline-none hover:text-muted-foreground"
        [ngClass]="{ 'rotate-180': !menuService.showSideBar }">
       <span class="material-symbols-outlined">
keyboard_double_arrow_left
</span>

      </button>
    </div>
    <!-- Separator -->
    <div class="pt-3">
      <hr class="border size-2/3 border-[#8f9bb3]" />
    </div>

    <div class="pt-16" *ngFor="let menu of menuService.pagesMenu">
      <ul class="flex flex-col gap-4 space-y-1 ">
        @for (item of menu.items; track item.label){
          <li
            *ngIf="!item.routePermission || authService.hasPermission(item.routePermission)"
          >
            <div
              class="group relative w-full text-muted-foreground"
              (click)="toggleMenu(item)"
              [routerLink]="item.route ? item.route : null"
              [ngClass]="{'disabled-link': !item.route}"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }">

              <!-- Icône -->
              <div class="pointer-events-none absolute top-[3px]">
                <span class="material-symbols-outlined menu-icon">{{ item.icon }}</span>
              </div>

              <!-- Étiquette -->
              <div class="flex h-9 cursor-pointer items-center justify-start rounded text-muted-foreground hover:bg-card hover:text-foreground">
                <a class="ml-12 truncate tracking-wide focus:outline-none text-[16px] font-normal text-[#000]">
                  {{ item.label }}
                </a>
              </div>
              <!-- Arrow Icon -->
              <button
                *ngIf="item.children"
                [ngClass]="{ hidden: !menuService.showSideBar, 'rotate-90': item.expanded }"
                class="pointer-events-none absolute top-1 right-0 flex items-center p-1 text-muted-foreground/50 transition-all duration-500">
                <span class="material-symbols-outlined icon" style="font-size: 22px">
                  chevron_right
                </span>
              </button>
              <!-- Tooltip -->
              <div class="fixed w-full" *ngIf="!menuService.showSideBar">
      <span class="z-1 absolute left-14 -top-[34px] w-auto min-w-max origin-left scale-0 rounded-md bg-foreground p-2 text-xs font-bold text-white bg-black shadow-md transition-all duration-200 group-hover:scale-100">
        {{ item.label }}
      </span>
              </div>
            </div>
            <app-sidebar-submenu [submenu]="item"></app-sidebar-submenu>
          </li>
        }
      </ul>

      <div class="pt-3" *ngIf="menu.separator">
        <hr class="border-dashed border-border" />
      </div>
    </div>

  </div>
</div>
